import React, { useRef } from "react";
import { Link } from "gatsby";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import ReactPlayer from 'react-player';
import "slick-carousel/slick/slick.css";

import * as styles from "../styles/components/heros.module.css";

function Heros({ items }) {

  const settings = {
    speed: 500,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    pauseOnFocus: false,
    dots: true,
    arrows: false,
  };

  const refPlayer = useRef(null);

  return (
    <div className={styles.root}>

      <Helmet>
        <body className="text-color-change" />
      </Helmet>

      <Slider {...settings}>
        {items.map(({ _key, image, imageMobile, video, videoUrl, title, blurb, link }) => {
          return (
            <div className={styles.item} key={_key}>
              {video ? (
                <>
                  {videoUrl && (
                    <div className={styles.video} ref={refPlayer}>
                      <ReactPlayer 
                        url={videoUrl}
                        playing={true}
                        loop={true}
                        controls={false}
                        volume={0}
                        muted={true}
                        playsinline={true}
                        width="100%"
                        height="100%"
                        config={{
                          vimeo: {
                            playerOptions: { 
                              background: true,
                              quality: '1080p' 
                            }
                          }
                        }}
                        onStart={()=>refPlayer.current.classList.add(styles.active)}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {image && image.asset && (
                    <picture className={styles.image}>
                      {imageMobile && imageMobile.asset && (
                        <>
                          <source media="(min-width:768px)" srcSet={imageUrlFor(buildImageObj(image)).auto("format").url()} />
                          <source media="(max-width:767px)" srcSet={imageUrlFor(buildImageObj(imageMobile)).auto("format").url()} />
                        </>
                      )}
                      <img src={imageUrlFor(buildImageObj(image)).auto("format").url()} alt={image.alt} />
                    </picture>
                  )}
                </>
              )}
              <div className={styles.content}>
                {title && (<h2 className={styles.title}>{title}</h2>)}
                {blurb && (<p className={styles.blurb}>{blurb}</p>)}
                {link && link.url && 
                  <>
                    {link.blank ? (
                      <a href={link.url} className={cn("view--more", styles.link)} target="_blank" rel="noreferrer">{link.text}</a>
                    ) : (
                      <Link to={link.url} className={cn("view--more", styles.link)}>{link.text}</Link>
                    )}
                  </>
                }
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default Heros;
