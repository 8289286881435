import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";

import Heros from "../components/heros";
import FlexibleContent from "../components/flexible-content";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query BensenEverydayTemplateQuery($id: String!) {
    bensenEveryday: sanityBensenEveryday(id: { eq: $id }) {
      id
      title
      description
      featuredImage {
        ...SanityImage
        alt
      }
      heros {
        _key
        image {
          ...SanityImage
          alt
        }
        imageMobile {
          ...SanityImage
          alt
        }
        video
        videoUrl
        title
        blurb
        link {
          blank
          text
          url
        }
      }
      flexibleContent {
        ... on SanityFcContentBlock {
          _key
          _type
          alignRight
          _rawText
        }
        ... on SanityFcFeaturedProducts {
          _key
          _type
          fillerImage {
            ...SanityImage
            alt
          }
          fillAlignRight
          featuredProducts {
            _key
            product {
              title
              slug {
                current
              }
              description
              featuredImage {
                ...SanityImage
                alt
              }
              secondaryFeaturedImage {
                ...SanityImage
                alt
              }
            }
          }
        }
        ... on SanityFc2ColumnImage {
          _key
          _type
          paddedLeft
          paddedRight
          imageLeft {
            ...SanityImage
            alt
            caption
          }
          imageRight {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcSlider {
          _key
          _type
          slides {
            _key
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcBensenEveryday {
          _key
          _type
          intro
          overrides {
            _key
            bensenEveryday {
              title
              slug {
                current
              }
              description
              featuredImage {
                ...SanityImage
                alt
              }
            }
          }
        }
      }
    }
  }
`;

const BensenEverydayTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const bensenEveryday = (data || {}).bensenEveryday;

  return (
    <Layout>
      <Seo
        title={bensenEveryday.title}
        description={bensenEveryday.description}
        image={bensenEveryday.featuredImage}
      />

      {bensenEveryday.heros && (<Heros items={bensenEveryday.heros} />)}
      {bensenEveryday.flexibleContent && (<FlexibleContent items={bensenEveryday.flexibleContent} />)}
    </Layout>
  );
};

export default BensenEverydayTemplate;
